/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Layout from "../components/layout"
import { CpdForm, transformer, validator } from "../components/cpd-form"
import {
  dataStorageKeys,
  getLocalStorageItem,
  setLocalStorageItem,
  updateStorageItem
} from "../util/local-storage-wrapper"
import { ProgressBar } from "../components/progress-bar"
import { localToApiParams } from "../util/policy-mappings"
import { requestPolicySetup } from "../api/cpd-product"
import { graphql } from "gatsby"
import { addToKlaviyo } from "../api/klaviyo-list"
import { DirectDebitGuarantee } from "../components/direct-debit-guarantee"
import { CoverSummary } from "../components/cover-summary"

const badgeImageUrl = '/assets/site/logo-badge-dark.svg'

const guessDirectDebitDate = () => {
  const datePlus13 = (new Date(Date.now() + (13 * 24 * 60 * 60 * 1000)))
  const dayOfMonth = datePlus13.getDate()
  const month = datePlus13.getMonth() + 1
  const year = datePlus13.getFullYear()

  return  dayOfMonth >= 29
    ? `1/${month + 1}/${month === 12 ? year + 1 : year}`
    : `${dayOfMonth}/${month}/${year}`
}

const scrollToTop = () => {
  typeof window !== 'undefined' && window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

const YouAreProtected = ({ policy, activeStep }) => {
  const { portalUrl = '', policyRef = '', firstPaymentDate = '' } = policy || {}

  return activeStep === 4
    ? <section className="you-are-protected">
      <div className="protected-content">
        <h1>You are protected</h1>
        <h2 className="policy">{policyRef}</h2>
        <img src={badgeImageUrl} alt="cpd logo" />
        <div>
          <p>First payment due: {firstPaymentDate}</p>
          <a href={portalUrl}>
            <button className="button button-electric-blue">Login to view documents</button>
          </a>
          <button className="button contact">Contact us</button>
        </div>
      </div>
    </section>
    : null
}

const carDetailForm = [
  {
    required: true,
    name: 'reg',
    label: 'Car registration',
    disabled: true,
    localStorageValue: 'vehicle-data.reg'
  },
  {
    required: true,
    name: 'make',
    label: 'Make',
    disabled: true,
    localStorageValue: 'vehicle-data.make'
  },
  {
    required: true,
    name: 'model',
    label: 'Model',
    disabled: true,
    localStorageValue: 'vehicle-data.model'
  },
  {
    required: true,
    name: 'purchased',
    label: 'Date purchased',
    type: 'date',
    localStorageValue: 'vehicle-data.purchased'
  },
  {
    required: true,
    name: 'value',
    label: 'Estimated car value (£)',
    inputMode: 'numeric',
    transform: transformer.integer,
    localStorageValue: 'vehicle-data.value'
  },
  {
    required: true,
    name: 'mileage',
    label: 'Mileage',
    inputMode: 'numeric',
    transform: transformer.integer,
    localStorageValue: 'vehicle-data.mileage'
  }
]

const yourDetailsForm = [
  {
    required: true,
    name: 'title',
    label: 'Title',
    type: 'select',
    options: ['Mr', 'Mrs', 'Ms', 'Miss', 'Doctor', 'Sir', 'Lady', 'Reverend'],
    localStorageValue: 'user-data.title'
  },
  {
    required: true,
    name: 'name',
    label: 'First name',
    localStorageValue: 'user-data.name'
  },
  {
    required: true,
    name: 'surname',
    label: 'Surname',
    localStorageValue: 'user-data.surname'
  },
  {
    required: true,
    name: 'address',
    label: 'Address',
    localStorageValue: 'user-data.address',
    placeholder: 'Start typing an address (4+ characters)',
    type: 'address'
  },
  {
    required: true,
    name: 'email',
    label: 'Email',
    localStorageValue: 'user-data.email',
    validate: validator.email,
    transform: transformer.email
  },
  {
    required: true,
    name: 'phone',
    label: 'Phone',
    localStorageValue: 'user-data.phone',
    validate: validator.phone,
    transform: transformer.phone
  }
]

const payment = [
  {
    required: true,
    name: 'account',
    label: 'Name on account',
    localStorageValue: 'user-bank-data.account'
  },
  {
    required: true,
    name: 'sort',
    label: 'Sort code',
    localStorageValue: 'user-bank-data.sort',
    inputMode: 'numeric',
    validate: v => v.length === 8,
    transform: v => {
      const cleaned = transformer.integer(v)
      const sections = [
        cleaned.substr(0,2),
        cleaned.substr(2,2),
        cleaned.substr(4,2)
      ]
      return sections.filter(s => !!s).join('-')
    }
  },
  {
    required: true,
    name: 'number',
    label: 'Bank account number',
    localStorageValue: 'user-bank-data.number',
    inputMode: 'numeric',
    validate: v => !v.startsWith('00') && v.length === 8,
    transform: v => transformer.integer(v).substr(0, 8)
  }
]

export const pageQuery = graphql`
  query SignupPageQuery($id: String!) {
    content: markdownRemark(id: { eq: $id }) {
      directDebitDetails: html,
      frontmatter {
        disclaimer
      }
    }
  }
`

const Signup = ({ data }) => {
  const { content: {  frontmatter: {disclaimer}, directDebitDetails } } = data
  const [progress, setProgress] = React.useState(0)
  const [policy, setPolicy] = React.useState(null)
  const [selectedProduct, setSelectedProduct] = React.useState(null)

  React.useEffect(() => {
    if (!selectedProduct) {
      setSelectedProduct(getLocalStorageItem(dataStorageKeys.selectedProduct))
    }
  }, [setSelectedProduct, selectedProduct])

  const formSectionSubmit = (values, storageKey, progressLevel, overwrite = false) => {
    if (overwrite) {
      setLocalStorageItem(storageKey, values)
    }
    else {
      updateStorageItem(storageKey, values)
    }
    setProgress(progressLevel)
    scrollToTop()
  }

  const carDetailsSubmit = (e, values) => {
    formSectionSubmit(values, dataStorageKeys.vehicleData, 1)
    scrollToTop()
  }

  const yourDetailsSubmit = async (e, values) => {
    formSectionSubmit(values, dataStorageKeys.userData, 2, true)
    const { name, surname, email, phone } = values
    await addToKlaviyo({ name, surname, email, phone })
    scrollToTop()
  }

  const paymentSubmit = (e, values) => {
    formSectionSubmit(values, dataStorageKeys.userBankData, 3, true)
    scrollToTop()
  }

  const changeMe = (e, level) => {
    e.preventDefault()
    setProgress(level)
    scrollToTop()
  }

  const showAll = progress === 3

  const signupAction = async (e) => {
    e.preventDefault()
    const vehicleData = getLocalStorageItem(dataStorageKeys.vehicleData)
    const selectedProduct = getLocalStorageItem(dataStorageKeys.selectedProduct)
    const userData = getLocalStorageItem(dataStorageKeys.userData)
    const userBankData = getLocalStorageItem(dataStorageKeys.userBankData)

    const params = localToApiParams(vehicleData, selectedProduct, userData, userBankData)

    const apiResponse = await requestPolicySetup(params)

    setPolicy(apiResponse)
    setProgress(4)
    scrollToTop()
  }

  const signupButton = showAll
    ? <div className="signup-button">
      <button onClick={signupAction} className="button button-electric-blue signup">Sign me up</button>
    </div>
    : null

  const expectedDirectDebitDate = guessDirectDebitDate()

  return <Layout className="signup-page">
    <div className="signup">
      <ProgressBar activeStep={progress} setStep={setProgress}/>
      <div className={`signup-content${showAll ? ' column' : ''}`}>
      {showAll ? <><h1 className="check-your-details-title">Check your details</h1><p className="first-direct-debit-date-on-details" >Your first direct debit won’t be collected until {expectedDirectDebitDate}</p></> : null}
        <div className="signup-form">
          {
            progress === 0 || showAll
              ? <div className="form-container">
                <CpdForm
                  title="Car details"
                  disclaimer={disclaimer}
                  fields={carDetailForm}
                  name="car"
                  submitLabel="Next: Your details"
                  submitAction={showAll ? (e) => changeMe(e, 0) : carDetailsSubmit}
                  playback={showAll}
                  isH1={!showAll}
                />
              </div>
              : null
          }
          {
            progress === 1 || showAll
              ? <div className="form-container">
                  <CpdForm
                    title="Your details"
                    disclaimer={disclaimer}
                    fields={yourDetailsForm}
                    name="you"
                    showPreviousButton={true}
                    previousAction={(e)=> changeMe(e, 0)}
                    submitLabel="Next: Payment"
                    submitAction={showAll ? (e) => changeMe(e, 1) : yourDetailsSubmit}
                    playback={showAll}
                    isH1={!showAll}
                  />
                </div>
              : null
          }
          {
            progress === 2 || showAll
              ? <div className="form-container">
                  <CpdForm
                    title="Direct debit details"
                    fields={payment}
                    name="you"
                    showPreviousButton={true}
                    previousAction={(e)=> changeMe(e, 1)}
                    disclaimer={directDebitDetails}
                    expectedDirectDebitDate={expectedDirectDebitDate}
                    submitLabel="Next: Final step!"
                    submitAction={showAll ? (e) => changeMe(e, 2) : paymentSubmit}
                    playback={showAll}
                    isH1={!showAll}
                  />
                </div>
              : null
          }
        </div>
        
        {signupButton}
        <CoverSummary activeStep={progress} selectedProduct={selectedProduct}/>
        {signupButton}
      </div>
      {progress === 2 ? <DirectDebitGuarantee/> : null}
      <YouAreProtected activeStep={progress} policy={policy}/>
    </div>
  </Layout>
}

export default Signup
