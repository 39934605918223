/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { productIconMap, nameMap } from "../util/product-mappings"
import { ProdIcons, ProductTitle } from "./product-title"

const query = graphql`
  {
    warranty: file(name: { eq: "warranty-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
    service: file(name: { eq: "service-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
    assistance: file(name: { eq: "assistance-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
    carefree: file(name: { eq: "carefree-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          tandcs {
            relativePath
          }
        }
      }
    }
  }
`

export const CoverSummary = ({ activeStep, selectedProduct }) => {
  const { productCode, siteLabel, monthlyCost } = selectedProduct || {}
  
  let pdfTnCsLink = '';
  const cmsData = useStaticQuery(query)

  if(siteLabel){
    const productPathSlug = siteLabel.split(' ')[0].toLowerCase();
    const { [productPathSlug]: { props: { 
      bits: {
        tandcs,
      }
     } } } = cmsData
     pdfTnCsLink = "assets/"+tandcs.relativePath;
  }



  const bullets = productIconMap[productCode]
    ? productIconMap[productCode].map(product => {
      const { props: { 
        body
      } } =  cmsData[product]
      const subTitle = <h3 className="sub-title">{product}</h3>;
      return <div>{subTitle} <div dangerouslySetInnerHTML={{ __html: body }}/></div>
    })
    : null

  const pfdLink = pdfTnCsLink;
  return activeStep < 4 && selectedProduct
    ? <section className="cover-summary">
      <h2 className="smaller">Summary of your cover</h2>
      <div className="product__title-grid">
        <div className="product__icon">
          <ProdIcons products={productIconMap[productCode]}/>
        </div>
        <div className="product__title-text">{siteLabel}</div>
        <div className="product__price">
          £{monthlyCost}
        </div>
      </div>
      <a
        href={pfdLink}
        className="document-link"
        target="_blank"
        rel="noreferrer"
      >
        <button className="button button-electric-blue" title="Download PDF" >
            View full T&Cs
        </button>
      </a>
      <hr/>
      <div>
        <span className="covered-for">You're covered for:</span>
        {bullets}
      </div>
    </section>
    : null
}
