export const addToKlaviyo = async (params) => {
  const res = await fetch('/.netlify/functions/klaviyo-list', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })

  return await res.json()
}
