import { productTypes } from "../util/product-mappings"

export const requestAction = async (params) => {
  const soapAction = params.soapAction || 'QuoteRequest'
  const res = await fetch('/.netlify/functions/cpd-product', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...params, soapAction  })
  })

  return await res.json()
}

export const requestPolicySetup = async (params) => {
  const { policySetupResponse } = await requestAction(params)

  return policySetupResponse[0]
}

export const requestAllQuotes = async (params) => {
  const quoteResponses = await Promise.all(productTypes
    .map(product => requestAction({ ...params, product })))

  if (quoteResponses.some(res => res.error)) {
    return { error: quoteResponses.map(({ error }) => error).join(',') }
  }

  return quoteResponses.reduce((prev, { quotes }) => ([...prev, ...quotes]), [])
}
