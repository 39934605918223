/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

const ddImageUrl = '/assets/site/direct-debit.png'

const query = graphql`
  {
    ddGuarantee: file(name: { eq: "direct-debit-guarantee" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
        }
      }
    }
  }
`

export const DirectDebitGuarantee = () => {
  const { ddGuarantee: { props: { body, bits: { title } } } } = useStaticQuery(query)
console.log(title)
  return <div className="direct-debit">
    <div className="title-line">
      <h2>{title}</h2>
      <img src={ddImageUrl} alt="direct debit" />
    </div>
    <div dangerouslySetInnerHTML={{ __html: body }}/>
  </div>
}
