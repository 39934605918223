/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

export const ProdIcons = ({ products = [] }) => <>
  {!products.length || products.includes('warranty')
    ? <img src={`/assets/site/icons/warranty-dark.svg`} alt="warranty icon" />
    : null
  }
  {!products.length || (products.includes('warranty') && products.length > 1)
    ? <div className="bundle__plus">+</div>
    : null
  }
  {!products.length || products.includes('service')
    ? <img src={`/assets/site/icons/service-dark.svg`} alt="assistance icon" />
    : null
  }
  {!products.length || (products.includes('assistance') && products.includes('service'))
    ? <div className="bundle__plus">+</div>
    : null
  }
  {!products.length || products.includes('assistance')
    ? <img src={`/assets/site/icons/aa.svg`} alt="AA" />
    : null
  }
</>

export const ProductTitle = ({
  icon,
  price = '',
  title,
  iconColour = 'dark'
}) => {
  const iconName = icon === 'assistance'
    ? 'aa'
    : `${icon}-${iconColour}`

  const images = icon === 'carefree'
    ? <ProdIcons/>
    : <img src={`/assets/site/icons/${iconName}.svg`} alt={`${icon} icon`} />

  const priceEl = price !== ''
    ? <span className="product__price">{price}/month</span>
    : ''
  return <div className="product__title-grid">
    <div className="product__icon">
      {images}
    </div>
    <span className="product__title-text">{title}</span>
    <h3>{priceEl}</h3>
  </div>
}
