export const packageProducts = ['Warranty', 'Service', 'Assistance']

export const individualProducts = ['CPD001', 'CPD006', 'CPD005']

export const nameMap = {
  CPD001: packageProducts[0],
  CPD002: `${packageProducts[0]} + ${packageProducts[2]}`,
  CPD003: `${packageProducts[0]} + ${packageProducts[1]}`,
  CPD004: 'Carefree bundle',
  CPD005: packageProducts[2],
  CPD006: packageProducts[1],
  CPD007: `${packageProducts[1]} + ${packageProducts[2]}`,
}

export const associatedProductMap = {
  warranty: [
    'CPD001',
    'CPD002',
    'CPD003',
    'CPD004'
  ],
  service: [
    'CPD006',
    'CPD003',
    'CPD007',
    'CPD004'
  ],
  assistance: [
    'CPD005',
    'CPD002',
    'CPD007',
    'CPD004'
  ],
  carefree: ['CPD004']
}

export const productIconMap = {
  CPD001: ['warranty'],
  CPD002: ['warranty', 'assistance'],
  CPD003: ['warranty', 'service'],
  CPD004: ['warranty', 'service', 'assistance'],
  CPD005: ['assistance'],
  CPD006: ['service'],
  CPD007: ['service', 'assistance']
}

export const productTypes = [
  'Motor Warranty',
  'Assistance',
  'Motor Service Plan'
]
