export const localToApiParams = (vehicleData, selectedProduct, userData, userBankData) => {
  const addressParts = userData.address.split(', ')
  const address = {
    firstLine: addressParts[0],
    secondLine: addressParts[1],
    city: addressParts[2],
    postcode: addressParts[3]
  }
  const sort = userBankData.sort.replace(/-/g, '')
  const fuel = vehicleData.fuel[0]
  const transmission = vehicleData.transmission[0]

  return {
    ...vehicleData,
    ...selectedProduct,
    ...userData,
    ...userBankData,
    address,
    soapAction: 'DirectMonthlySales',
    sort,
    fuel,
    transmission
  }
}
