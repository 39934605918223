/** @jsx jsx */
import { jsx } from "theme-ui"

export const ProgressBar = ({ activeStep, setStep }) => {
  const getStepClass = (step, activeStep) => {
    if (step - 1 === activeStep) return 'in-progress'
    if (step > activeStep) return 'not-started'

    return ''
  }

  const getLineClass = (line, activeStep) => {
    if (activeStep === 0) {
      if (line === 'first') return 'fade-to-gray'
      return 'gray'
    }
    if (activeStep === 1) {
      if (line === 'first') return 'blue-to-fade'
      return 'fade-to-gray'
    }
    if (activeStep === 2) {
      if (line === 'first') return 'blue'
      return 'blue-to-fade'
    }
  }

  const ProgressIcon = ({ step, stepLabel } ) => {
    const thisClass = getStepClass(step, activeStep)

    return <div className="signup-step">
      <button
        className={thisClass || 'completed'}
        disabled={step > activeStep}
        onClick={() => setStep(step - 1)}
      >
        {step}
      </button>
      <span className={thisClass}>{stepLabel}</span>
    </div>
  }

  return activeStep < 4
    ? <div className="signup-progress">
      <ProgressIcon step={1} stepLabel="Car details"/>
      <div className="signup-line">
        <hr className={getLineClass('first', activeStep)} />
      </div>
      <ProgressIcon step={2} stepLabel="Your details"/>
      <div className="signup-line">
        <hr className={getLineClass('second', activeStep)} />
      </div>
      <ProgressIcon step={3} stepLabel="Payment"/>
    </div>
    : null
}
